import classes from "./Modal.module.css";
import { InfinitySpin } from "react-loader-spinner";
function Modal() {
  return (
    <div className={classes.modal}>
      <div className={classes.content}>
        <InfinitySpin color="grey" />
      </div>
    </div>
  );
}

export default Modal;
