import ColumnSelect from "./ColumnSelect";
import { useState } from "react";
function DataColumnHeader(props) {
  const [selectMax, setSelectMax] = useState(1);
  const [selections, setSelections] = useState([]);

  const updateSelections = (id, value, tabName, pos) => {
     selections[pos] = value;
     setSelections(selections);
     props.columnSelected(id, selections, tabName);
    //props.updateMap(tabName, id, pos, value);
  };

  const addMapping = () => {
    if (selectMax < 4) {
      setSelectMax(selectMax + 1);
    }
    //props.addMap(props.id);
  };

  const removeMapping = () => {
    if (selectMax > 1) {
      setSelectMax(selectMax - 1);
    }
    //props.remMap(props.id);
  };

  let selectors = [];
  // let selectMax = 1;
  // let tabData;
  // let column;
  // if (props.feedback[props.tabName] != undefined) {
  //   //console.log(props.feedback[props.tabName][props.id]);

  //   tabData = props.feedback[props.tabName];
  //   if (tabData[props.id] != undefined) {
  //     column = tabData[props.id];
  //     selectMax = column.selectMax;
  //   }

  //   //let selections = column.selections;
  // }

  for (let i = 0; i < selectMax; i++) {
    // let selection = "None";
    // if(column != undefined) {
    //   console.log(column);
    // }
    selectors.push(
      <div>
        <ColumnSelect
          opts={props.opts}
          id={props.id}
          pos={i}
          columnSelected={updateSelections}
          tabName={props.tabName}
          // selected={selection}
        />
      </div>
    );
  }

  return (
    <div>
      <div>
        <button onClick={addMapping}>+Map</button>
        <button onClick={removeMapping}>-Map</button>
      </div>

      {selectors}
    </div>
  );
}

export default DataColumnHeader;
