import { useState, useEffect } from "react";
import { apiConfig } from "../../apiConfig";

function SchemaPicker(props) {

  function onChange(event) {
    props.pickSchemaOption(event.target.value);
  }


  return (
    <select onChange={onChange}>
      <option key="0" value="0">
        New Mapping
      </option>
      {props.options.map((opt) => (
        <option key={opt.id} value={opt.id}>
          {opt.schemaName}
        </option>
      ))}
    </select>
  );
}

export default SchemaPicker;
