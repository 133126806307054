import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

async function handleLogin(instance) {
    instance.handleRedirectPromise().then(authResult => {
        const account = instance.getActiveAccount();
        if(!account) {
            instance.loginPopup(loginRequest).catch((e) => {
                console.error(e);
              });
        }
    }).catch(err=>{
        console.log(err);
    })
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  return <button onClick={() => handleLogin(instance)}>Sign in</button>;
};
