import Pane from "../ui/Pane";

function PriceListUpdatePane(props) {
  //props.statusData
  //props.getPriceListStatus
  //props.downloadExceptionFile
  //props.showDownload

  const downloadFile = () => {
    props.downloadExceptionFile(props.statusData);
  };

  let downloadBtn;
  let finished = props.statusData.finishedUpload;
  let statusContent;
  if (finished) {
    downloadBtn = <button onClick={downloadFile}>Download</button>;
    statusContent = <label>File ready click download.</label>;
  } else {
    downloadBtn = <button onClick={props.getPriceListStatus}>Refresh</button>
    statusContent = <label>Upload is still in operation.</label>;
  }

  return (
    <Pane>
      <label>Upload Status:</label>
      <div>
        {statusContent}
        {downloadBtn}
      </div>
    </Pane>
  );
}

export default PriceListUpdatePane;
