import { useState } from "react";
import { Grid } from "react-spreadsheet-grid";
import ManufacturerSelect from "./ManufacturerSelect";

function PricingGrid(props) {
  // const [page, setPage] = useState(0);
  // //const [pages, setPages] = useState([]);
  // const pageSize = 10;
  // let pageCount = 0;

  // const backPage = () => {
  //   setPage(page - 1);
  // };

  // const fwdPage = () => {
  //   setPage(page + 1);
  // };

  // let pages = [];
  // let colIndex = 0;
  // let pageSnip = 0;
  // let pageIndex = [];
  // while (colIndex < props.columns.length) {
  //   if (pageSnip <= pageSize) {
  //     pageIndex.push(props.columns[colIndex]);
  //     pageSnip++;
  //   }
  //   if (
  //     pageSnip === pageSize ||
  //     (props.columns.length - colIndex < pageSize &&
  //       colIndex === props.columns.length - 1)
  //   ) {
  //     pageSnip = 0;
  //     pages.push(pageIndex);
  //     // let pagesUpdate = pages;
  //     // pagesUpdate.push(pageIndex);
  //     // setPages(pagesUpdate);
  //     pageIndex = [];
  //     pageCount++;
  //   }
  //   colIndex++;
  // }

  return (
    <div>
      <div>
        <h2>{props.tabName}</h2>
        <div>
          <label>Company:</label>
          <ManufacturerSelect
            manufacturers={props.manufacturers}
            tabName={props.tabName}
            pickManufacturer={props.pickManufacturer}
          />
        </div>
        {/* <div>
          <button disabled={page === 0} onClick={backPage}>
            Prev
          </button>
          <button disabled={page === pageCount - 1} onClick={fwdPage}>
            Next
          </button>
        </div> */}
      </div>
      <div className={"Grid_div"}>
        <Grid
          isScrollable
          //columns={pages[page]}
          columns={props.columns}
          rows={props.rows}
          //isColumnsResizable
          //onColumnResize={onColumnResize}
          getRowKey={(row) => row.id}
          headerHeight={120}
        />
      </div>
    </div>
  );
}

export default PricingGrid;
