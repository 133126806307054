import classes from "./UploadWrapper.module.css";

import { FileUploader } from "react-drag-drop-files";

function UploadWrapper(props) {
  return (
    <div>
      <FileUploader
        handleChange={props.changeHandler}
        name="file"
        types={["xlsx"]}
        classes={[classes.uw]}
      />
    </div>
  );
}

export default UploadWrapper;
