
export const msalConfig = {
  auth: {
    clientId: "7362de85-c7d3-4137-bb84-e853e5290b1b",
    authority: "https://login.microsoftonline.com/3af3ed18-6578-404a-b7c1-654ad73a244e", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://tools.roomready.io/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["openid"],
};
