import { /*Grid,*/ Input } from "react-spreadsheet-grid";
import { useState, useEffect } from "react";
//import ColumnSelect from "./ColumnSelect";
//import TabSelector from "./TabSelector";
import PricingGrid from "./PricingGrid";
import DataColumnHeader from "./DataColumnHeader";
//import { AccessTokenEntity } from "@azure/msal-common";
import { apiConfig } from "../../apiConfig";
//import UiModal from "../ui/UiModal";

function SchemaTool(props) {
  const [tabInfo, setTabInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pickedManufacturers, setPickedManufacturers] = useState({});
  //const [showProdIdWarning, setShowProdIdWarning] = useState(false);
  const [shownProdIdWarning, setShownProdIdWarning] = useState(false);
  const [tabMaps, setTabMaps] = useState({});
  //const [mappingFeedback, setMappingFeedback] = useState([]);
  //let tabMaps = {};
  const columnSelected = (id, value, tabName) => {
    let map = tabMaps[tabName] === undefined ? {} : tabMaps[tabName];
    map[id] = value;
    tabMaps[tabName] = map;
    console.log(tabMaps);
    setTabMaps(tabMaps);
    props.mappingColumns(tabMaps);
    if (!shownProdIdWarning && value.includes("Product Id")) {
      setShownProdIdWarning(true);
      alert(
        "Ensure Product Id column matches the Id in Connect Wise Manage, or else import will only add new items."
      );
      //setShowProdIdWarning(true);
    }
  };

  function pickManufacturer(value, tabName) {
    pickedManufacturers[tabName] = value;
    setPickedManufacturers(pickedManufacturers);
    props.changedManufacturers(pickedManufacturers);
    console.log(pickedManufacturers);
  }

  // function acceptProdIdWarning() {
  //   setShowProdIdWarning(false);
  //   setShownProdIdWarning(true);
  // }

  

  

  // const updateSelections = (tabName, id, pos, value) => {
    
  //   let tab = mappingFeedback[tabName] === undefined ? {} : mappingFeedback[tabName];
  //   let column = tab[id] === undefined  ? {} : tab[id];
  //   let select = column[pos] === undefined  ? {} : column[pos];
  //   select = value;
  //   column[pos] = select;
  //   tab[id] = column;
  //   mappingFeedback[tabName] = tab;
  //   setMappingFeedback(mappingFeedback)
  //   console.log(mappingFeedback);
  // }

  useEffect(() => {
    fetch(apiConfig.uri + "v1/getmappingdefaults", {
      method: "POST",
      body: JSON.stringify(props.fileDetails),
      headers: new Headers({
        Authorization: props.accessToken,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let tabs = [];
        let mappingDefault = data.mappingDefaults;
        let inc = 0;
        //let tabData = [];
        data.tabs.forEach((tabName) => {
          let table = data.table[tabName];
          const initColumns = [];
          //let columns = [];
          for (let i = 0; i < table.columnCount; i++) {
            //let columnData = {};
            //let selectMax = [];
            //columnData.selectMax = selectMax.push(1);
            // let selections = [];
            // selections.push("None");
            // columnData.selections = selections;

            //columns[i] = columnData;

            let columnVal = i + 1;
            initColumns[i] = {
              title: () => {
                return (
                  <div>
                    <DataColumnHeader
                      opts={mappingDefault.defaultPriceMappingFields}
                      id={i}
                      columnSelected={columnSelected}
                      tabName={tabName}
                      //addMap={addMapping}
                      //remMap={removeMapping}
                      //updateMap={updateSelections}
                      //feedback={mappingFeedback}
                    />
                  </div>
                );
              },
              value: (row, { focus }) => {
                return (
                  <Input
                    value={row["col" + columnVal]}
                    //focus={focus}
                    //onChange={onFieldChange(row.id, "name")}
                  />
                );
              },
              width: 300,
            };
          }
          //tabData[tabName] = columns;

          let tab = {};
          tab.rows = table.rows;
          tab.columns = initColumns;
          tab.name = tabName;
          tabs[inc] = tab;
          inc++;
        });
        //setMappingFeedback(tabData);
        //console.log(mappingFeedback);
        setTabInfo(tabs);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  if (isLoading) {
    return <h2>loading...</h2>;
  }
  return (
    <section>
      {/* {showProdIdWarning && <UiModal>
        <h2>Ensure Product Id column matches the Id in Connect Wise Manage, or else import will only add new items.</h2>
        <button onClick={acceptProdIdWarning} >Ok</button>
      </UiModal>} */}
      {tabInfo.map((tab) => (
        <PricingGrid
          mappings={tabMaps}
          tabName={tab.name}
          rows={tab.rows}
          columns={tab.columns}
          manufacturers={props.manufacturers}
          pickManufacturer={pickManufacturer}
        />
      ))}
    </section>
  );
}

export default SchemaTool;
