function ManufacturerSelect(props) {
  function manufacturerSelected(event) {
    props.pickManufacturer(event.target.value, props.tabName);
  };

  return (
    <select onChange={manufacturerSelected}>
      <option key={0}></option>
      {props.manufacturers.map((opt) => (
        <option key={opt.id}>{opt.name}</option>
      ))}
    </select>
  );
}

export default ManufacturerSelect;
