// import ReactSelect from "react-select";
// import React, {Component} from 'react';

function ColumnSelect(props) {
  function onChange(event) {
    // let selected = [];
    // event.map((item) => selected.push(item.label));
    // props.columnSelected(props.id, event[0].label, props.tabName);
    props.columnSelected(props.id, event.target.value, props.tabName, props.pos);
  }
  //console.log(props.selected)
  // const options = [];
  // props.opts.map((opt) => {
  //   options.push({value: opt.id, label: opt.name});
  // })

  // return (
  //   <ReactSelect
  //     options={options}
  //     isMulti
  //     closeMenuOnSelect={false}
  //     hideSelectedOptions={false}
  //     onChange={onChange}
  //     allowSelectAll={false}
      
  //   />
  // );
  return (
    <select onChange={onChange} value={props.selected}>
      {props.opts.map((opt) => (
        <option key={opt.id} value={opt.name}>{opt.name}</option>
      ))}
    </select>
  );
}

export default ColumnSelect;
