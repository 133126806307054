import { useState, useRef } from "react";
import SchemaPicker from "./SchemaPicker";
import Pane from "../ui/Pane";
import SchemaTool from "./SchemaTool";
import { apiConfig } from "../../apiConfig";
import Modal from "../ui/Modal";
import UploadWrapper from "../ui/UploadWrapper";
import PriceListUpdatePane from "./PriceListUpdatePane";

function PriceSheetFileUpload(props) {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSchemaTool, setIsSchemaTool] = useState();
  const [schemaId, setSchemaId] = useState("0");
  const [mappedColumns, setMappedColumns] = useState({});
  const [uploadFileResp, setUploadFileResp] = useState({});
  const [manufacturers, setManufacturers] = useState([]);
  const [pickedManufacturers, setPickedManufacturers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [mappingNames, setMappingNames] = useState([]);
  const [compareFileSelected, setCompareFileSelected] = useState(false);
  const [comparedFile, setComparedFile] = useState();
  const [compareUploadFileResp, setCompareUploadFileResp] = useState({});
  const [showStatusPane, setShowStatusPane] = useState(false);
  const [statusId, setStatusId] = useState("");
  const [statusData, setStatusData] = useState({});

  const [showCompareStatusPane, setShowCompareStatusPane] = useState(false);
  const [compareStatusId, setCompareStatusId] = useState("");
  const [comapreStatusData, setCompareStatusData] = useState({});

  let content;

  const cancelPriceList = () => {
    setSelectedFile(null);
    setIsFilePicked(false);
    setUploadFileResp({});
    setIsSchemaTool(false);
  };

  const cancelResultList = () => {
    setComparedFile(null);
    setCompareFileSelected(false);
    setCompareUploadFileResp({});
    setStatusId("");
    setStatusData({});
    setShowStatusPane(false);
  };

  const fillMappingNames = () => {
    setIsLoading(true);
    fetch(apiConfig.uri + "v1/pricelistschema", {
      method: "GET",
      headers: new Headers({
        Authorization: props.apiKey,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setMappingNames(data);
        setIsLoading(false);
        // content = (
        //   <div>
        //     <SchemaPicker options={data} pickSchemaOption={pickSchemaOption} />
        //     <button onClick={handleSchemaChange}>Create New Mapping</button>
        //   </div>
        // );
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const changedManufacturers = (value) => {
    setPickedManufacturers(value);
    console.log(pickedManufacturers);
  };

  const fillManufactures = () => {
    setIsLoading(true);
    fetch(apiConfig.uri + "v1/cwManufacturers", {
      method: "GET",
      headers: new Headers({
        Authorization: props.apiKey,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setManufacturers(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const mappingName = useRef();

  const mappingColumns = (mappedColumns) => {
    setMappedColumns(mappedColumns);
    console.log(JSON.stringify(mappedColumns));
  };

  const changeHandler = (file) => {
    setIsLoading(true);
    //console.log(file);
    //setSelectedFile(event.target.files[0]);
    setSelectedFile(file);
    //console.log(file);
    setIsFilePicked(file.name.length > 0);
    const formData = new FormData();
    formData.append("file", file);
    fetch(apiConfig.uri + "v1/uploadfile", {
      method: "POST",
      body: formData,
      headers: new Headers({
        //"Content-Type": "multipart/form-data; boundary=" + file.length,
        Authorization: props.apiKey,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.errorMsg) {
          cancelPriceList();
          alert(data.errorMsg);
        } else {
          setUploadFileResp(data);
          setIsLoading(false);
          fillManufactures();
          fillMappingNames();
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const submitChangeHandler = (file) => {
    setIsLoading(true);
    //console.log(event);
    setComparedFile(file);
    //console.log(event.target.files.length);
    setCompareFileSelected(file.name.length > 0);
    const formData = new FormData();
    formData.append("file", file);
    fetch(apiConfig.uri + "v1/uploadfile", {
      method: "POST",
      body: formData,
      headers: new Headers({
        //"Content-Type": "multipart/form-data; boundary=" + file.length,
        Authorization: props.apiKey,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.errorMsg) {
          cancelResultList();
          alert(data.errorMsg);
        } else {
          setCompareUploadFileResp(data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handlePriceList = () => {
    setIsLoading(true);
    console.log(selectedFile);
    //https://www.pluralsight.com/guides/uploading-files-with-reactjs

    let priceBody = {};
    priceBody.fileName = uploadFileResp.fileName;
    priceBody.schemaId = schemaId;
    fetch(apiConfig.uri + "v1/comparePriceList", {
      method: "POST",
      body: JSON.stringify(priceBody),
      headers: new Headers({
        //"Content-Type": "multipart/form-data; boundary=" + file.length,
        Authorization: props.apiKey,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.errorMsg) {
          alert(data.errorMsg);
        } else {
          // var a = document.createElement("a");
          // var blob = new Blob([s2ab(atob(data.priceListCompare))], {
          //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          // });
          // a.href = window.URL.createObjectURL(blob);
          // a.download =
          //   data.mappingName + "_v" + data.version + "_import_review.xlsx";
          // //a.download = data.mappingName + "_" + data.version + "_" + Date.now() + ".xlsx";
          // a.click();
          // //setComparedFile();
          setCompareStatusId(data.statusId);
          setShowCompareStatusPane(true);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const handleSchemaChange = () => {
    console.log(selectedFile);
    //https://www.pluralsight.com/guides/uploading-files-with-reactjs
    setIsSchemaTool(true);
  };

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const submitSchemaChange = () => {
    setIsLoading(true);
    //https://www.pluralsight.com/guides/uploading-files-with-reactjs

    const mappingPost = {
      schemaName: mappingName.current.value,
      schemaMappings: mappedColumns,
      manufacturers: pickedManufacturers,
    };
    console.log(mappingPost);
    fetch(apiConfig.uri + "v1/pricelistschema", {
      method: "POST",
      body: JSON.stringify(mappingPost),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: props.apiKey,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((mappingItem) => {
        console.log(mappingItem);
        setIsSchemaTool(false);
        fillMappingNames();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        alert(err.message);
      });
  };

  const pickSchemaOption = (id) => {
    setSchemaId(id);
    console.log(schemaId);
  };

  function handleSubmitCW() {
    setIsLoading(true);
    let submitPrices = {};
    submitPrices.fileName = compareUploadFileResp.fileName;
    fetch(apiConfig.uri + "v1/submitpricetocw", {
      method: "POST",
      body: JSON.stringify(submitPrices),
      headers: new Headers({
        //"Content-Type": "multipart/form-data; boundary=" + file.length,
        Authorization: props.apiKey,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStatusId(data.statusId);
        setShowStatusPane(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  const getPriceListStatus = () => {
    setIsLoading(true);
    fetch(apiConfig.uri + "v1/pricelistimportstatus/"+statusId, {
      method: "GET",
      headers: new Headers({
        //"Content-Type": "multipart/form-data; boundary=" + file.length,
        Authorization: props.apiKey,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setStatusData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  const getPriceListCompareStatus = () => {
    setIsLoading(true);
    fetch(apiConfig.uri + "v1/pricelistcomparestatus/"+compareStatusId, {
      method: "GET",
      headers: new Headers({
        //"Content-Type": "multipart/form-data; boundary=" + file.length,
        Authorization: props.apiKey,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setCompareStatusData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  const downloadExceptionFile = (statusPayload) => {
    var a = document.createElement("a");
    var blob = new Blob([s2ab(atob(statusPayload.priceBookExceptions))], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });
    a.href = window.URL.createObjectURL(blob);
    a.download = statusPayload.fileName;
    //a.download = data.mappingName + "_" + data.version + "_" + Date.now() + ".xlsx";
    a.click();
  }


  const handleDeleteMapping = () => {
    setIsLoading(true);
    fetch(apiConfig.uri + "v1/pricelistschema/" + schemaId, {
      method: "DELETE",
      headers: new Headers({
        //"Content-Type": "multipart/form-data; boundary=" + file.length,
        Authorization: props.apiKey,
      }),
    })
      .then((response) => {
        //should just respond
        fillMappingNames();
        setSchemaId("0");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  if (isSchemaTool) {
    content = <div></div>;
  } else if (isFilePicked && schemaId !== "0") {
    content = (
      <Pane>
        <label>{uploadFileResp.fileName}:</label>
        <SchemaPicker
          options={mappingNames}
          accessToken={props.apiKey}
          pickSchemaOption={pickSchemaOption}
        />
        <button onClick={handlePriceList}>Compare Price List</button>
        <div>
          <button className={"Delete_Map"} onClick={handleDeleteMapping}>Delete Mapping</button> 
        </div>
      </Pane>
    );
  } else if (isFilePicked && schemaId === "0") {
    content = (
      <Pane>
        <label>{uploadFileResp.fileName}:</label>
        <SchemaPicker
          options={mappingNames}
          accessToken={props.apiKey}
          pickSchemaOption={pickSchemaOption}
        />
        <button onClick={handleSchemaChange}>Create New Mapping</button>
      </Pane>
    );
  } else {
    content = (
      <Pane>
        <p>Please select a file.</p>
      </Pane>
    );
  }

  const cancelSchemaTool = () => {
    setIsSchemaTool(false);
  };

  let newSchemaContent = (
    <Pane>
      <div>
        <label>{uploadFileResp.fileName}:</label>
      </div>
      <div>
        <label htmlFor="mappingName">Mapping Name:</label>
        <input type="text" required id="mappingName" ref={mappingName} />
        <button onClick={submitSchemaChange}>Submit Mapping</button>
        <button onClick={cancelSchemaTool}>Cancel</button>
      </div>
      <div>
        {/*<MappingTool */}
        {/* <SchemaTool */}
        <SchemaTool
          accessToken={props.apiKey}
          fileDetails={uploadFileResp}
          mappingColumns={mappingColumns}
          manufacturers={manufacturers}
          changedManufacturers={changedManufacturers}
        />
      </div>
    </Pane>
  );

  let compareUpload;
  if (compareFileSelected) {
    compareUpload = <button onClick={cancelResultList}>Cancel</button>;
  } else {
    compareUpload = !isLoading && (
      <UploadWrapper changeHandler={submitChangeHandler} />
    );
  }

  let pushCWContent = (
    <Pane>
      <label>Result to send to CW:</label>
      {compareUpload}
      {compareFileSelected && (
        <div>
          <label>{compareUploadFileResp.fileName}:</label>
          <button onClick={handleSubmitCW}>Submit To CW</button>
        </div>
      )}
    </Pane>
  );

  let priceListUpload;
  if (isFilePicked) {
    priceListUpload = <button onClick={cancelPriceList}>Cancel</button>;
  } else {
    priceListUpload = !isLoading && (
      <UploadWrapper changeHandler={changeHandler} />
    );
  }

  return (
    <div>
      {isLoading && <Modal />}
      <Pane>
        <label>Price List:</label>
        {/* <input type="file" name="file" onChange={changeHandler} /> */}
        {priceListUpload}
      </Pane>

      {content}

      {showCompareStatusPane && <PriceListUpdatePane statusData={comapreStatusData} getPriceListStatus={getPriceListCompareStatus} downloadExceptionFile={downloadExceptionFile}/>}

      {isSchemaTool && newSchemaContent}

      {pushCWContent}
      {showStatusPane && <PriceListUpdatePane statusData={statusData} getPriceListStatus={getPriceListStatus} downloadExceptionFile={downloadExceptionFile}/>}
    </div>
  );
}

export default PriceSheetFileUpload;
